<template>  
  <div class="ticket-table">
    <el-form ref="form" :model="operationForm" label-width="0" :rules="tableFormFormRules">
      <el-table
      :data="operationForm.operationTableData"
      border
      style="width: 100%">
      <el-table-column
        v-for="item in operationTableColumn"
        :key="item.prop"
        :prop="item.prop"
        align="center"
        :min-width="item.width"
        >
        <template slot="header">
          <div>
            <span v-if="[1,2].includes(type) && ['paymentType','receiptNumber','payer','receiptCurrency','receiptAmount','receivingAccountId','receivingDate'].includes(item.prop)">
              <span style="color: red;">*</span>{{ item.label }}
            </span>
            <span v-else-if="![1,2].includes(type) && ['paymentType','receiptNumber','payer','receiptCurrency','receiptAmount'].includes(item.prop)">
              <span style="color: red;">*</span>{{ item.label }}
            </span>
            <span v-else>{{ item.label }}</span>
          </div>
        </template>
        <template slot-scope="scope">
         <el-form-item v-if="item.prop === 'paymentType'"
            :prop="
              'operationTableData.' +
              scope.$index +
              '.paymentType'
            "
            :rules="[
              {
                required: true,
                message:'请选择',
                trigger: 'change',
              }
            ]"
          >
          <el-select
              v-model="scope.row.paymentType"
              placeholder="请选择"
              class="w100"
              v-if="isEdit"
            >
              <el-option
                v-for="item in paymentListOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
          </el-select>
          <span v-else>{{ scope.row.paymentType?'电汇':'支票' }}</span>
         </el-form-item>
         <el-form-item v-else-if="item.prop === 'receiptNumber'" 
          :prop=" 'operationTableData.' + scope.$index + '.receiptNumber'" 
          :rules="[
              {
                required: true,
                message:'请输入',
                trigger: 'blur',
              },
              { 
                validator: (rule, value, callback) => {
                  const isNumber = /^[a-zA-Z0-9]+$/.test(value);
                  if (!isNumber) {
                    callback('必须是数字或字母');
                  } else {
                    callback();
                  }
                }, 
                trigger: 'blur' 
              }
            ]"
          >
            <el-input v-model.trim="scope.row.receiptNumber" v-if="isEdit" maxlength="50" @blur="receiptNumberBlurFn"></el-input>
            <span v-else>{{ scope.row.receiptNumber }}</span>
         </el-form-item>
         <el-form-item v-else-if="item.prop === 'payer'" 
          :prop=" 'operationTableData.' + scope.$index + '.payer'"
          :rules="[
              {
                required: true,
                message:'请输入',
                trigger: 'change',
              }
            ]"
          >
              <WewooRemoteSelect
                v-if="isEdit"
                v-model="scope.row.payer"
                :api="TicketManagementInteractor.payerListPageApi"
                :debounceTime="800"
                allowCreate
                :maxlength="90"
                :clearable="false"
                type="textarea"
                deleteScrollbar
                :autosize="{ minRows: 2, maxRows: 10}"
                class="wewoo-remote-select"
              />
            <!-- <el-input v-model="scope.row.payer" v-if="isEdit" maxlength="90"></el-input> -->
            <span v-else>{{ scope.row.payer }}</span>
         </el-form-item>
         <el-form-item v-else-if="item.prop === 'receiptCurrency'"
            :prop="
              'operationTableData.' +
              scope.$index +
              '.receiptCurrency'
            "
            :rules="[
              {
                required: true,
                message:'请选择',
                trigger: 'change',
              }
            ]"
          >
          <el-select
              v-model="scope.row.receiptCurrency"
              placeholder="请选择"
              class="w100"
              v-if="isEdit"
            >
              <el-option
                v-for="item in currencyFormOptions"
                :key="item.financeSetupId"
                :label="item.financeName"
                :value="item.financeSetupId"
              ></el-option>
            </el-select>
            <span v-else>{{ scope.row.receiptCurrency ? '人民币' :'美元' }}</span>
         </el-form-item>
          <el-form-item v-else-if="item.prop === 'receiptAmount'"
            :prop="
              'operationTableData.' +
              scope.$index +
              '.receiptAmount'
            "
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                message: $t('rules.M2014'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="scope.row.receiptAmount"
              oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              @blur="receiptAmountBlurFn"
              maxlength="10"
              v-if="isEdit"
            ></el-input>
            <span v-else>{{ addThousandSeparator(scope.row.receiptAmount) }}</span>
          </el-form-item>
          <el-form-item v-else-if="item.prop === 'receivingAccountId'"
          :prop="
              'operationTableData.' +
              scope.$index +
              '.receivingAccountId'
            "
            :rules="[
              {
                required: [1,2].includes(type) ? true: false,
                message: '请选择',
                trigger: 'change',
              }
            ]"
          
          >
            <el-select
              v-model="scope.row.receivingAccountId"
              placeholder="请选择"
              v-if="isEdit"
            > 
              <el-option
              v-for="item in accountList"
                :key="item.shroffAccountId"
                :label="item.payee + '/' + item.bankDeposit + ' / ' + item.bankAccount"
                :value="item.shroffAccountId"
              ></el-option>
            </el-select>
            <span v-else>{{ scope.row.receivingAccountId | receivingAccountName}}</span>
          </el-form-item>
          <el-form-item v-else-if="item.prop === 'receivingDate'"
          :prop="
              'operationTableData.' +
              scope.$index +
              '.receivingDate'
            "
            :rules="[
              {
                required: [1,2].includes(type) ? true: false,
                message: '请选择',
                trigger: 'change',
              }
            ]"
          >
          <el-date-picker
            size="small"
            v-model="scope.row.receivingDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
            v-if="isEdit"
          ></el-date-picker>
          <span v-else>{{ scope.row.receivingDate || '--' }}</span>
          </el-form-item>
          <span v-else-if="item.prop === 'receiptStatus'">
            {{ scope.row[item.prop] | receiptStatusFilter }}
          </span>
         <div v-else> 
          <span v-if="item.isMoney">{{addThousandSeparator(scope.row[item.prop])}}</span>
          <span v-else>{{ scope.row[item.prop]?scope.row[item.prop]:'--' }}</span>
         </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        v-if="type === 1 || type === 7"
        width="52">
        <template slot-scope="scope">
          <el-button   type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRowFn"></el-button>
        </template>
      </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
  let that;
  import { operationTableColumn,paymentListOptions,currencyFormOptions,statusList } from '../utils'
  import { addThousandSeparator,elFormErrorScrollIntoView } from 'kits'
  import WewooRemoteSelect from 'wewoo-remote-select'
  import { TicketManagementInteractor } from '@/core'
  export default {
    name: 'SerialNumberTable',
    components: { WewooRemoteSelect },
    props: {
      // 查询弹窗新增票据还是新增核销false是新增
      queryDialogIsExist:{
        type:Boolean,
      },
      operationForm: {
        type: Object,
        default: () => ({
          operationTableData: [
            {
              receiptCode:'PJ240680862',
              receiptStatus:'已确认',
              paymentType:1,
              receiptNumber:'1068',
              payer:'Thromes',
              ticketCollectionTime:'2024-07-02 18:00:01',
              receiptCurrency:'美元',
              receiptAmount:'2000.00',
              verificationBalance:1100.00,
              receivingAccountId:'1022 0010 2100 100 8097',
              receivingDate:'2024-07-02',
              
            }
          ]
        })
      },
       /**
       * 1批量生成收/退款单
       * 2生成收/退款单
       * 3银行退回
       * 4确认票据
       * 5待确认核销票据
       * 6已确认核销票据
       * 7批量银行退回
       */
      type:{
        type: Number,
        default: 1
      },
      // 收款账号列表
      accountList:{
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        TicketManagementInteractor,
        tableFormFormRules:{},
        paymentListOptions,// 付款方式
        currencyFormOptions,// 币种
        isEdit: true, // 是否编辑
        statusList,
      }
    },
    filters: {
      // 格式收款账号显示
      receivingAccountName(val){
        const currReceivingAccount = that.accountList.find(item => item.shroffAccountId === val);
        return currReceivingAccount? currReceivingAccount.payee  + '/' + currReceivingAccount.bankDeposit + ' /'+ currReceivingAccount.bankAccount : '--';  
      },
      // 票据状态名称显示
      receiptStatusFilter(val){
        return statusList.find(item => item.code === val).name
      },

    },
    computed: {
      // 表格列
      operationTableColumn() {
        if(this.type === 1 || this.type === 2 || this.type === 4){
          this.isEdit = true
          return operationTableColumn
        }else if(this.type === 3 ||this.type === 7){
          this.isEdit = false
          const noColumn = ['receiptStatus']
          return operationTableColumn.filter(item => !noColumn.includes(item.prop))
        }else if(this.type === 5){
          this.isEdit = false
          const noColumn = ['receivingAccountId','receivingDate']
          return operationTableColumn.filter(item => !noColumn.includes(item.prop))
        }else if(this.type === 6){
          this.isEdit = false
          return operationTableColumn
        }
        
      }
    },
    created() {
      that = this;
    }, 
    methods: {
      // 删除一行
      deleteRowFn() {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
            this.$emit('deleteRow')
          }).catch((err) => {
            console.log(err)
          })
      },
        // 页面滚动到未填项
      scrollToError() {
        this.$nextTick(() => {
          elFormErrorScrollIntoView('.el-form-item__error')
        })
      },
      // 校验表格必填项
      checkTableForm() {
        return new Promise((resolve) => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              resolve(true);
            } else {
              this.scrollToError()
              resolve(false);
            }
          });
        });
      }, 
      // 处理千分位
      addThousandSeparator(val) {    
        return addThousandSeparator(val,true)    
      },
      // 票据金额失去焦距重新计算票据余额
      receiptAmountBlurFn(){
        this.$emit('receiptAmountBlurFn')
      },
      // 票据单号失去焦距校验
      receiptNumberBlurFn(){
        if(this.type === 4) this.$emit('receiptNumberBlurFn')
      }
    }
  } 
</script>
<style scoped lang="scss">
.wewoo-remote-select /deep/ .el-autocomplete-suggestion {
  width: auto !important;
  min-width: 100px;
  text-align: left;
}
.ticket-table{
  ::v-deep.el-table {
    .cell{
      padding: 0 2px;
      .el-form-item{
        margin: 14px 0;
        .el-select{
          width: 100% !important;
        }
        .el-date-editor{
          width: 100% !important;
          &.el-input{
            .el-input__inner{
              padding: 0 4px 0 26px;
            }
          }
        }
        .el-input{
          .el-input__inner{
            text-align: center;
            padding: 0 4px;
          }
        }
      }
    }
  }
  .add-row{
    text-align: center;
    padding: 10px 0 30px;
    span{
      cursor: pointer;
      color: rgb(64, 158, 255);
    }
  }
}

</style>