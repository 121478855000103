<template>
  <div class="ticket-management-edit pro-container">
    <div class="hearder-group">
      <div class="hearder-group-tit">
        <el-page-header :title="$t('other.back')" :content="ticketInfo.receiptNumber" @back="goBack"></el-page-header>
        <el-tag class="ml10" size="mini" :type="ticketInfo.receiptStatus == 1 ?'': 'danger'"> {{ ticketInfo.receiptStatus | receiptStatusFilter }}</el-tag>
        <el-tag class="ml10" size="mini" :type="ticketInfo.receiptVerificationStatus == 0 ? 'danger':(ticketInfo.receiptVerificationStatus == 1 ? 'warning': 'success') "> {{ ticketInfo.receiptVerificationStatus | receiptVerificationStatusFilter }}</el-tag>
        <el-tag class="ml10" size="mini" :type="ticketInfo.generatePaymentReceiptStatus == 0 ? 'danger':(ticketInfo.receiptVerificationStatus == 1 ? 'warning': 'success') "> {{ ticketInfo.generatePaymentReceiptStatus | generatePaymentReceiptStatusFilter }}收款单
        </el-tag>
      </div> 
      <div>
        <el-button size="mini" v-allowed="['TICKET:WRITEOFF']" @click="receiveRefundSlipFn(ticketInfo.receiptStatus === 0 ? 5 : 6)" v-if="ticketInfo.receiptStatus === 0 || ticketInfo.receiptStatus === 1">核销</el-button>
        <el-button size="mini" v-allowed="['TICKET:CONFIRM']" @click="receiveRefundSlipFn(4)" v-if="ticketInfo.receiptStatus === 0">确认</el-button>
        <el-button size="mini" v-allowed="['TICKET:GENERATEREFUNDRECEIPT']" @click="receiveRefundSlipFn(2)" v-if="ticketInfo.receiptStatus === 1">生成收退款单</el-button>   
        <el-button size="mini" v-allowed="['TICKET:EDIT']" @click="handleEdit" v-if="ticketInfo.receiptStatus === 0 && ticketInfo.optFlag">编辑</el-button>   
        <el-button size="mini" v-allowed="['TICKET:RESUBMIT']" @click="handleReSubmit" v-if="ticketInfo.receiptStatus === 2 || ticketInfo.receiptStatus === 3">重新提交</el-button>   
        <el-button type="danger" v-allowed="['TICKET:TURNDOWN']" size="mini" @click="turnDownFn" v-if="ticketInfo.receiptStatus === 0">驳回</el-button>   
        <el-button type="danger" v-allowed="['TICKET:BANKREFUND']" size="mini" @click="receiveRefundSlipFn(3)" v-if="ticketInfo.receiptStatus === 1">银行退回</el-button>   
      </div>
    </div>
    <div class="ticket-management-edit-cont">
      <el-tabs v-model="activeName">
        <el-tab-pane label="票据信息" name="ticketInfor">
          <ticketInfo :ticketInfo.sync="ticketInfo" :editBol="false" ></ticketInfo>
          <div class="file-box" v-if="fileBol">
            <filePanel  ref="filePanel" :editBol="false"  :noteAttachment.sync="noteAttachment" :emailScreenshot.sync="emailScreenshot"></filePanel>
          </div>
        </el-tab-pane>
        <el-tab-pane label="核销结果" name="writeOffResults">
          <div class="write-off-results">
            <p>核销金额合计： ${{ addThousandSeparator(ticketInfo.verificationAmountTotal) }}</p>
            <el-table
              :data="writeOffResults"
              border
              style="width: 480px">
              <el-table-column
                prop="orderCode"
                label="销售单号"
                align="center"
               >
              </el-table-column>
              <el-table-column
                prop="businessNameCn"
                label="客户代表"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="writeOffAmount"
                align="center"
                label="核销金额">
                <template slot-scope="scope">
                  <span :style="{color: scope.row.writeOffAmount > 0 ? '#303133' : 'red'}">
                    {{ addThousandSeparator(scope.row.writeOffAmount) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="核销明细" name="writeOffDetails">
           <div class="write-off-results">
            <p>核销金额合计： ${{ addThousandSeparator(ticketInfo.verificationAmountTotal) }}</p>
            <el-table
              :data="writeOffDetails"
              border
              style="width: 100%">
              <el-table-column
                prop="orderCode"
                label="销售单号"
                align="center"
               >
              </el-table-column>
              <el-table-column
                prop="businessNameCn"
                label="客户代表"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="orderTotalAmount"
                label="应收金额"
                align="center"
                >
                <template slot-scope="scope">
                  <span>
                    {{ addThousandSeparator(scope.row.orderTotalAmount) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectionAmount"
                label="已收金额"
                align="center"
                >
                <template slot="header">
                  <el-tooltip  effect="dark"  placement="top">
                    <div slot="content" style="width: 200px;">
                      该PI已完成收款单应收金额-已完成退款单实退金额
                    </div>
                    <div>
                      <span>已收金额</span>
                      <i class="el-icon-warning-outline" style="color: #606266;font-size: 16px;margin-left: 2px;"></i>
                    </div>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{ addThousandSeparator(scope.row.collectionAmount) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="unpaidAmount"
                label="未收金额"
                align="center"
                >
                <template slot="header">
                  <el-tooltip  effect="dark"  placement="top">
                    <div slot="content" style="width: 200px;">
                      该PI的应收金额-已完成的收款单中该PI的应收金额 + 已完成状态的退款单中该PI的实退金额。
                    </div>
                    <div>
                      <span>未收金额</span>
                      <i class="el-icon-warning-outline" style="color: #606266;font-size: 16px;margin-left: 2px;"></i>
                    </div>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{ addThousandSeparator(scope.row.unpaidAmount) }}
                  </span>
                </template> 
              </el-table-column>
              <el-table-column
                prop="writeOffAmount"
                align="center"
                label="核销金额">
                <template slot-scope="scope">
                  <span :style="{color: scope.row.writeOffAmount > 0 ? '#303133' : 'red'}">
                    {{ addThousandSeparator(scope.row.writeOffAmount) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="remark"
                label="备注"
                align="center"
                >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.remark ? scope.row.remark : '--' }}
                  </span>
                </template> 
              </el-table-column> 
              <el-table-column
                prop="refundReceiptNumber"
                label="收款单号"
                align="center"
                >
                <template slot="header">
                  <el-tooltip  effect="dark"  placement="top">
                    <div slot="content" style="width: 200px;">
                      1. 行上无收款单号，代表该行核销记录未生成收退款单。
                      <br/>
                      2. 行上有收款单号代表已生成收退款单，若要修改该PI核销金额，请手工新增一行，选择该PI后，核销金额输入需调整的核销金额。
                    </div>
                    <div>
                      <span>收款单号</span>
                      <i class="el-icon-question" style="color: red;font-size: 16px;margin-left: 2px;"></i>
                    </div>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.refundReceiptNumber ? scope.row.refundReceiptNumber : '--' }}
                  </span>
                </template> 
              </el-table-column>
              <el-table-column
                prop="financeStatus"
                label="状态"
                align="center"
                >
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.financeStatus" :type="setSalesOrderStatus(scope.row).type" size="small" plain>{{ setSalesOrderStatus(scope.row).label }}</el-tag>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="verificator"
                label="核销人"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="核销时间"
                align="center"
                width="220px"
                >
              </el-table-column>
            </el-table>
           </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="operationLog">
          <OperateLogs ref="operateLogs" receiptOptBol v-if="activeName == 'operationLog'" :showModule="false" :bizId="id" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 新增弹窗 -->
    <addNewNote :visibleAdd.sync="visibleAdd" :source="2" v-if="visibleAdd" :financeReceiptId = "id" @addSuccessFn="getTicketInfo"></addNewNote>
    <!-- 驳回弹窗 -->
    <turnDown :visibleTurnDown.sync="visibleTurnDown" @turnDownConfirmFn="turnDownConfirmFn" v-if="visibleTurnDown"></turnDown>
     <!-- 操作弹窗 -->
    <operateDialog  :type=operateType :visibleOperate.sync="visibleOperate" :receiveRefundSlipList="receiveRefundSlipList" @operateConfirm="getTicketInfo"></operateDialog>
  </div>
</template> 
<script>
  import ticketInfo from './components/ticket-info.vue'
  import filePanel from './components/file-panel.vue'
  import OperateLogs from '@/components/operate-logs'
  import operateDialog from './components/operate-dialog.vue'
  import turnDown from './components/turn-down.vue'
  import addNewNote from './components/add-new-note.vue';
  import { paymentStatusOptions,writeOffStatusOptions,statusList } from './utils';
  import { TicketManagementInteractor } from '@/core'
 import { addThousandSeparator,dateTimeFormat } from 'kits'
  export default {
    name: 'Ticket-managementDetails',
    components: {
      ticketInfo,
      filePanel,
      OperateLogs,
      operateDialog,
      turnDown,
      addNewNote
    },
    data() {
      return {
        id:'',//票据id
        visibleAdd:false,//新增弹窗
        activeName:'ticketInfor',//tabs激活的标签
        fileBol:false,//是否有附件
        noteAttachment: [],//票据附件
        emailScreenshot:[],//邮件截图 
        ticketInfo: {
          id:'',//票据id
          optFlag:false,//是否可编辑
          receiptNumber: '',//票据单号
          receiptCode: '',//票据流水号
          createTime: '',//收票时间
          paymentType: 0,//收款方式
          payer:'',//付款方
          receiptCurrency: 0,//票据币种
          receiptAmount: '',//票据金额
          verificationBalance: '',//票据余额
          receiptBank: '',//出票银行
          remark: '',//备注
          verificationAmountTotal: '900.00',//总金额核销
          generatePaymentReceiptStatus:0,//收退款状态
          receiptVerificationStatus:0,//核销状态
          receiptStatus:0,//票据状态
          source:0,//票据来源
          creator: '', // 创建人
        },
        writeOffResults:[],//核销结果
        writeOffDetails:[],//核销明细
        paymentStatusOptions,
        statusList,
        writeOffStatusOptions,
        visibleOperate:false,//操作弹窗
        operateType:1,//操作弹窗类型
        receiveRefundSlipList:[],//收退款单数据
        visibleTurnDown: false, //驳回弹窗
        financeStatusTags :[
          {
            type:'danger',
            financeStatusValue:'7',
            label:'待业务确认'
          },
          {
            type:'warning',
            financeStatusValue:'1',
            label:'待银行处理'
          },
          {
            type:'primary',
            financeStatusValue:'8',
            label:'审核中'
          },
          {
            type:'success',
            financeStatusValue:'4',
            label:'已完成'
          },
          {
            type:'danger',
            financeStatusValue:'3',
            label:'已驳回'
          },
          {
            type:'info',
            financeStatusValue:'5',
            label:'已作废'
          },
          {
            type:'danger',
            financeStatusValue:'9',
            label:'审核驳回'
          },
          {
            type:'danger',
            financeStatusValue:'10',
            label:'已撤销'
          } 
        ],////销售状态tag
      }
    },
    created(){
      this.id = this.$route?.query?.id;
      if(this.id)this.getTicketInfo();
    },
    filters: {
      receiptStatusFilter(val){
      return statusList.find(item => item.code === val).name
      },
      receiptVerificationStatusFilter(val){
        return writeOffStatusOptions.find(item => item.value === val).label
      },
      generatePaymentReceiptStatusFilter(val){
        return paymentStatusOptions.find(item => item.value === val).label
      },
    },
    methods: {
      // 赋值销售单状态
      setSalesOrderStatus(row){
        return this.financeStatusTags?.find(tag => tag.financeStatusValue === row.financeStatus) ?? {}
      },
      // 获取票据信息
      async getTicketInfo(){
        const res = await TicketManagementInteractor.getReceiptDetailApi(this.id);
        if(res?.code === '000000'){
          for(let key in this.ticketInfo){
            this.ticketInfo[key] = res?.data?.[key];
            this.noteAttachment = res?.data?.files?.filter(file=> file.bizType === 0)
            this.emailScreenshot = res?.data?.files?.filter(file=> file.bizType ===1)
            this.writeOffResults = res?.data?.verifiesResult??[]
            this.writeOffDetails = res?.data?.verifies??[]
          }
        }
        this.fileBol = true
      },
      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
      // 驳回
      turnDownFn() {
        this.visibleTurnDown = true
      },
      // 驳回确认
      async turnDownConfirmFn(msg){
        const params = {
          financeReceiptIds: [this.id],
          rejectionReason: msg,
        }
        const res = await TicketManagementInteractor.rejectApi(params)
        if(res?.code === '000000'){
          this.$message.success('操作成功')
          this.visibleTurnDown = false
          this.getTicketInfo()
        }
      },
      // 点击编辑
      handleEdit(){
        this.$router.push({
          name:'ticketManagementEdit',
          query: {
            id:this.id
          }
        })
      },
      // 操作按钮操作
      async receiveRefundSlipFn(type){
        this.operateType = type
        let ids = [this.id]
        await this.processingDataFn(ids)
        this.visibleOperate = true
      },
    //处理数据  
    async processingDataFn(ids){
      const apiName = this.operateType === 3 ? 'detailWhenBankReturnApi' : 'batchDetailWithOptApi'
      const res = await TicketManagementInteractor[apiName](ids)
        if(res?.code === '000000'){
          this.receiveRefundSlipList = res?.data?.map(item=>{
            // 生成收退款单和确认票据 带出默认收款账号
            if(this.operateType === 2){
              if(item?.riseDefaultAccountId)item.receivingAccountId = item.riseDefaultAccountId
              if(!item?.receivingDate)item.receivingDate = dateTimeFormat('yyyy-MM-dd',new Date().getTime())
            }
            item.delVerifyIds = []
            item.moreSearch = true
            item.noteAttachment = item.files.filter(file=> file.bizType === 0)
            item.emailScreenshot = item.files.filter(file=> file.bizType ===1)
            item.ticketTableData = item.verifies
            delete item.files
            delete item.verifies
            return {
              ...item
            }
          })
        }
    },
     // 处理重新提交 
     handleReSubmit(){
      this.visibleAdd = true
    },
    // 处理千分位
    addThousandSeparator(val) {    
      return addThousandSeparator(val,true)    
    },
    }
  }
</script>
<style lang="scss" scoped>
.ticket-management-edit{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .hearder-group{
    display: flex;
    justify-content: space-between;
    .hearder-group-tit{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-page-header{
        margin: 0;
      }
    }
  }
  .ticket-management-edit-cont{
    padding: 0 20px;
  }
  .file-box{
    padding:20px;
  }
  .write-off-results{
    p{
      padding: 20px 0 20px 0;
      font-size: 18px;
      color: #333;
    }
  }
}
</style>